<template>
  <div class="feed-view">
    <div class="feed-line"></div>
    <active-challenges v-if="showTropaion" />
    <transition name="fade-transition" mode="out-in">
      <spartan-plus v-if="showSpartanPlus" can-close feed-view />
    </transition>
    <feed-notification
      v-if="feedNotification"
      :notification-data="feedNotification.data"
    />
    <template>
      <feed-card v-for="(post, index) in postsList" :key="index" :post="post" />
      <div v-if="showIntersector" v-intersect="onIntersect">
        <feed-card-skeleton />
        <feed-card-skeleton v-if="page === 1" />
      </div>
      <div
        v-if="showReloadButton"
        class="refresh-button"
        @click="showIntersector = true"
      >
        <span class="material-icons"> refresh </span>
      </div>
    </template>
  </div>
</template>
<script>
import config from "@/config";
import { mapGetters, mapActions } from "vuex";
import { isEmpty } from "lodash";
export default {
  data: () => ({
    page: 1,
    // offset: 15,
    postsList: [],
    isLoading: false,
    showIntersector: true,
    showReloadButton: false,
    showTropaion: config.showTropaion,
  }),
  computed: {
    ...mapGetters([
      "userData",
      "userSubscription",
      "showSubscriptionCard",
      "feedNotification",
      "feedNextPage",
    ]),
    showSpartanPlus() {
      const SUPPORTED_COUNTRIES = ["US"];
      let { spartan_country_code } = this.userData;
      let status = this.userSubscription?.status;

      return (
        this.showSubscriptionCard &&
        SUPPORTED_COUNTRIES.includes(spartan_country_code) &&
        (!status || status !== "active")
      );
    },
  },
  components: {
    "feed-card": () => import("./components/FeedCard.vue"),
    "feed-card-skeleton": () => import("./components/FeedCardSkeleton.vue"),
    "feed-notification": () => import("./components/FeedNotification.vue"),
    "active-challenges": () =>
      import("@/views/TropaionPage/components/ActiveChallenges.vue"),
    "spartan-plus": () =>
      import("@/views/SubscribePage/components/SpartanPlus.vue"),
  },
  methods: {
    ...mapActions(["getFeed"]),
    async onIntersect(entries, observer, isIntersecting) {
      if (!isIntersecting || this.isLoading) return;
      try {
        this.isLoading = true;
        let list = await this.getFeed(this.page);
        this.isLoading = false;
        if (isEmpty(list) || !this.feedNextPage) this.showIntersector = false;
        this.postsList = [...this.postsList, ...list];
        this.page++;
      } catch (err) {
        console.log(err, "Get feed error");
        this.showIntersector = false;
        this.isLoading = false;
        this.showReloadButton = true;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.feed-view {
  max-width: 670px;
  min-height: calc(2 * 474px);
  width: 100%;
  padding-bottom: 100px;
  .feed-line {
    height: 7px;
    background: #ffffff;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
  .refresh-button {
    margin: 15px auto;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background-color: #121212;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include cursorPointer;
    .material-icons {
      opacity: 0.9;
    }
  }
}
</style>